<template>
  <!--
  <v-autocomplete
        outlined
        dense
        v-model="departmentShortnameSelected"
        :items="departmentList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Département"
        item-text="name"
        item-value="shortname"
        class="select-department ml-16 shrink"
      ></v-autocomplete>
  -->
  <div>
    <div class="d-flex flex-row mb-3">
      <p class="text-2xl mb-6">Google Analytics par site</p>
      <v-space></v-space>
      <date-range-filter
        @valueChanged="setDates"
        @frequencyChanged="setFrequency"
        periodType="past-and-today"
        format="date"
        :frequencies="['daily', 'weekly', 'monthly']"
        frequency="daily"
        :range="30"
        :initialValue="dateSelected"
        :showBtnList="['YESTERDAY', 'LAST_WEEK', 'LAST_MONTH']"
        :outlined="true"
        :allowNoLimit="false"
        wrapperClass="date-range-filter"
      ></date-range-filter>
    </div>

    <v-card class="pa-0">
      <v-card-text>
        <v-text-field
          v-model="searchStr"
          label="Search"
          @input="search"
          dense
        ></v-text-field>

        <v-progress-linear v-show="loading" indeterminate></v-progress-linear>

        <div v-if="filteredData.length !== 0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Nom du site (id)</th>
                  <th class="text-center">Domaine</th>
                  <th class="text-center">Sessions</th>
                  <th class="text-center">Views</th>
                  <th class="text-center">Views / Session</th>
                  <th class="text-center">GA View Id used</th>
                  <th class="text-center">Sample ratio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredData" :key="item.id">
                  <td class="text-left">
                    <b class="primary--text">
                      {{ item.department_id ? "-> " : "" }}
                      {{ item.name }} ({{ item.site_id }})
                    </b>
                  </td>

                  <td class="text-left">{{ item.domain }}</td>
                  <td class="text-right">
                    {{ item.department_id ? "-> " : "" }}
                    <b>{{ item.sessions }}</b>
                  </td>
                  <td class="text-right">{{ item.views }}</td>
                  <td class="text-right">
                    {{ item.views_by_session }}
                  </td>
                  <td class="text-right">{{ item.ga_view_id }}</td>
                  <td class="text-right">
                    {{ item.sample_ratio ? `${item.sample_ratio}%` : "100%" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>
          {{ noDataMessage }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiPencil } from "@mdi/js";
import axios from "@axios";
import { saveRmraView } from "@/utils/rmr-analytics";
import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
import debounce from "lodash/debounce";
import {
  frequencyList,
  frequencyIdByName,
  getDateStr,
  ONE_DAY_MICROTIME,
} from "@/utils/dates";

let defaultNoDataMessage = "Retrieving data ...";

const TODAY = new Date();

export default {
  name: "GaSites",
  props: {
    departmentShortnameSelected: String,
  },
  components: {
    DateRangeFilter,
  },
  watch: {
    // departmentShortnameSelected: {
    //   // the callback will be called immediately after the start of the observation
    //   immediate: true,
    //   handler(val, oldVal) {
    //     console.log("val", val, "oldVal", oldVal);
    //     router.push({ name: `ga-sites-${val}` });
    //     this.debouncedGetData();
    //   },
    // },
  },
  created() {
    this.initDatesInitialValue();
    this.debouncedGetData();

    this.$store.dispatch("common/getDepartmentList");
  },
  mounted() {
    saveRmraView(this.$route);
    this.debouncedGetData();
  },
  data() {
    return {
      componentKey: 0,
      usePrettyNumbers: true,
      loading: false,
      departmentIdSelected: 0,
      frequencySelected: 2,
      dateList: [],
      dateSelected: null,
      dailyDateList: [],
      previousDayStr: null,
      weeklyDateList: [],
      previousMondayStr: null,
      monthlyDateList: [],
      previousFirstOfMonthStr: null,
      searchStr: "",
      searchTimeout: null,
      noDataMessage: defaultNoDataMessage,
      data: [
        /* {
        "site_id": 1,
        "name": "Vie pratique féminin",
        "code": "viepratique",
        "shortname": "vpf",
        "domain": "www.viepratique.fr",
        "department_id": null,
        "frequency": 2,
        "date": "2022-09-14",
        "ga_view_id": 165996786,
        "views": 362017,
        "sessions": 90597,
        "views_by_session": 3.9959,
        "sample_ratio": null
        },... */
      ],
      icons: {
        mdiPencil,
      },
      filteredData: [],
    };
  },
  methods: {
    initDatesInitialValue() {
      const yesterday = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
      this.dateSelected = [
        this.getDateStr(yesterday),
        this.getDateStr(yesterday),
      ];
      console.log("initDatesInitialValue this.dates", this.dates);
    },
    getDateStr(date) {
      return getDateStr(date);
    },
    setDates(dates) {
      console.log("GaSites valueChanged setDates $emit periodChange", dates);
      this.dateSelected = dates;
      this.debouncedGetData();
    },
    prettyNbs(x, sep = " ") {
      if (typeof x == "undefined") return x;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    // setDepartment() {
    //   router.push({ name: `ga-sites-${this.departmentShortnameSelected}` });
    //   this.debouncedGetData();
    // },
    setFrequency(frequencyObj) {
      console.log("setFrequency(frequencyObj)", frequencyObj);
      this.frequencySelected = frequencyObj.id;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      this.data = [];
      this.filteredData = [];
      this.noDataMessage = defaultNoDataMessage;
      // let propsDepartmentShortname = this.departmentShortnameSelected;
      // this.departmentIdSelected = this.departmentList.filter(function (el) {
      //   return el.shortname == propsDepartmentShortname;
      // })[0]["id"];
      if (!this.frequencySelected || this.dateSelected.length !== 2) {
        console.log("missing params");
        return false;
      }
      // let url = `/ga_sites/filtered/3/5/2022-08-08/2022-08-10`; // 3 is the CRM department id, 5 is monthly
      const url = `/ga_sites/filtered/${this.departmentIdSelected}/${this.frequencySelected}/${this.dateSelected[0]}/${this.dateSelected[1]}`;
      console.log("getData ----------- url: ", url);
      this.loading = true;
      const { data } = await axios.get(url);
      this.loading = false;
      console.log("response", data);
      if ("error" in data) {
        this.noDataMessage = data.error;
        return;
      }
      // JSON.parse(JSON.stringify(data));
      this.data = data;
      this.setFilteredData();
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      const filteredData = [];
      const searchStrLC = this.searchStr.toLowerCase();
      for (let i = 0; i < this.data.length; i++) {
        if (
          this.searchStr == "" ||
          this.data[i]["name"].toLowerCase().includes(searchStrLC) ||
          this.data[i]["domain"].toLowerCase().includes(searchStrLC)
        ) {
          if (this.usePrettyNumbers) {
            this.data[i]["views"] = this.prettyNbs(this.data[i]["views"]);
            this.data[i]["sessions"] = this.prettyNbs(this.data[i]["sessions"]);
            this.data[i]["views_by_session"] =
              Math.round(this.data[i]["views_by_session"] * 100, 2) / 100;
          }
          filteredData.push(this.data[i]);
        }
      }
      this.filteredData = filteredData;
      if (filteredData.length == 0) {
        this.noDataMessage = "Aucune donnée pour ce.s filtre.s";
      }
    },
  },
  computed: {
    departmentList() {
      return this.$store.getters["common/getDepartmentList"];
    },
  },
};
</script>
<style>
.date-range-filter {
  max-width: 500px;
}
</style>
